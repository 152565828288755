import { ImageRatio } from '@components/ImageThumbnailRatio/ImageRatio';
import VnrLink from '@components/VnrLink';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useTranslate from 'src/hook/useTranslate';

import styles from './Menu.module.scss';

const MenuDesktop = dynamic(() => import('./MenuDesktop'), {
  ssr: false
});
const MenuMobile = dynamic(() => import('./MenuMobile'), {
  ssr: false
});

const Menu = ({ featurePostGroups, isBannerHidden }) => {
  const translate = useTranslate();
  const [topPosition, setTopPosition] = useState(isMobile ? '29vw + 25px ' : '140px');

  useEffect(() => {
    if (isBannerHidden) {
      // Once the banner is hidden, move the menu to the top
      setTopPosition('0px');
    }
  }, [isBannerHidden]);

  return (
    <div id="menuVnrebates" style={{ top: `${topPosition}`, position: 'sticky' }} className={styles.Menu}>
      <div className={styles.logo}>
        <VnrLink to="/">
          <ImageRatio
            src={translate.home.logo}
            alt="vnRebates"
            ratio="1500:406.4"
          />
        </VnrLink>
      </div>
      <div className={styles.desktopMenu}>
        <MenuDesktop 
          featurePostGroups={featurePostGroups} 
          translate={translate}
        />
      </div>
      <div className={styles.mobileMenu}>
        <MenuMobile translate={translate} />
      </div>  
    </div>
  )
}
export default Menu;